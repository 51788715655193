import "./Usdt.css";
import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { getShopRaffle, updateRaffle } from "../../apis/tasks";
const Usdt = () => {
  const [datas, setDatas] = useState();
  const [updateData, setUpdateData] = useState();
  const items = [
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000", // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      image: "/Images/redeeemrequest_prof.svg",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    {
      image: "/Images/redeeemrequest_prof.svg",
      username: "BE Community",
      wallet: "0xe80512Bf5B5....Cbd3101",
      chain: "Solana",
      action: "cancel",
      points: "100,000",
    },
    // Add more rows as needed
  ];

  const [rows, setRows] = useState(items);

  const handleCheckbox = (index) => {
    const updatedRows = rows.map((row, i) =>
      i === index
        ? { ...row, action: row.action === "check" ? "cancel" : "check" }
        : row
    );
    setRows(updatedRows);
  };

  async function getData() {
    const res = await getShopRaffle();
    console.log("this is response", res);
    setDatas(res?.data);
  }
  React.useEffect(() => {
    getData();
  }, [updateData]);

  async function approvveTranscation(reddem) {
    try {
      const data = {
        reddemId: reddem, // Example value for reddemId
        status: true, // Example value for status
      };
      const res = await updateRaffle(data);
      console.log(res);
      setUpdateData(res);
    } catch (error) {
      console.log(error);
    }
  }
  async function decline(reddem) {
    try {
      const data = {
        reddemId: reddem, // Example value for reddemId
        status: false, // Example value for status
      };
      const res = await updateRaffle(data);
      console.log(res);
      setUpdateData(res);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="Usdt__main">
      <TableContainer
        component={Paper}
        className="tableContainer__"
        sx={{
          backgroundColor: "#090909",
          border: "2px solid #121212",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="custom table">
          <TableHead>
            <TableRow>
              <TableCell className="Usdt__tableheadtext">User Image</TableCell>
              <TableCell className="Usdt__tableheadtext">
                Telegram Username
              </TableCell>
              <TableCell className="Usdt__tableheadtext">
                Wallet Address
              </TableCell>
              <TableCell className="Usdt__tableheadtext">Chain</TableCell>
              <TableCell className="Usdt__tableheadtext">Points</TableCell>
              <TableCell className="Raffleticket__tableheadtext">
                Customer ID
              </TableCell>
              <TableCell className="Usdt__tableheadtext">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas?.map((row, index) => {
              if (row?.redeemType == "usdt")
                return (
                  <TableRow key={index}>
                    <TableCell style={{ borderBottom: "1px solid #121212" }}>
                      <Avatar alt={row.username} src={row.image} />
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Usdt__rowText"
                    >
                      {row?.usersId?.userName}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Usdt__rowText1"
                    >
                      {row?.walletAddress
                        ? `${row.walletAddress.slice(
                            0,
                            2
                          )}...${row.walletAddress.slice(-2)}`
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Usdt__rowText1"
                    >
                      {row?.chain}
                    </TableCell>
                    {/* <TableCell style={{borderBottom:"1px solid #121212"}} className='RedeemRequest__rowText2'>{row.amount}</TableCell> */}
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Usdt__rowText2"
                    >
                      {row?.bePoints}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #121212" }}
                      className="Raffleticket__rowText1"
                    >
                      {row?.usersId?.beUserId}
                    </TableCell>
                    {row?.approved ? (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src="/Images/tick.svg" alt="Tick" />
                      </TableCell>
                    ) : row?.approved == false ? (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src="/Images/cancel.svg" alt="Cancel" />
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img
                          onClick={() => {
                            approvveTranscation(row?._id);
                          }}
                          src="/Images/tick.svg"
                          alt="Tick"
                        />

                        <img
                          onClick={() => {
                            decline(row?._id);
                          }}
                          src="/Images/cancel.svg"
                          alt="Cancel"
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Usdt;
