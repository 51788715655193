import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import config from "../../config";

import Loading from "../../components/loading";

import { addNewTask, getNewTaskGroup, updateTask } from "../../apis/tasks";

import "./index.css";

const EditTaskPage = () => {
  const goTo = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rewards, setRewards] = useState(0);
  const [link, setLink] = useState("");
  const [type, setType] = useState(0);
  const [img, setImg] = useState("");
  const [taskId, setTaskId] = useState("");
  const [imageSource, setImageSource] = useState();

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ id: "", groupName: "" });
  const [taskGroup, setTaskGroup] = useState();

  const handleChange = (event) => {
    const selectedItem = taskGroup?.find(
      (item) => item.groupName === event.target.value
    );
    setSelectedGroup({
      id: selectedItem?._id,
      groupName: selectedItem.groupName,
    });
  };

  const handleCancelButtonClick = () => {
    localStorage.removeItem("description");
    localStorage.removeItem("task_id");
    localStorage.removeItem("rewards");
    localStorage.removeItem("title");
    localStorage.removeItem("link");
    localStorage.removeItem("type");
    localStorage.removeItem("img");
    goTo("/task");
  };

  const handlePublishButtonClick = async () => {
    setIsLoading(true);
    let id = localStorage.getItem("task_id");
    if (id === undefined || id === null) {
      const formData = new FormData();
      formData.append("file", imageSource);
      try {
        const response = await axios
          .post(`${config.main_url}upload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setSnackbarMessage("Uploaded Successfully!");
            setSnackbarStatus("success");
            setIsOpenSnackbar(true);
            const run = async () => {
              let res = await addNewTask({
                title,
                description,
                rewards,
                link,
                type: selectedGroup?.groupName,
                img: response.data.fileName,
                taskGoup: selectedGroup?.id,
              });
              if (res.success === false) {
                setSnackbarMessage(res.message);
                setSnackbarStatus("warning");
                setIsOpenSnackbar(true);
              } else {
                console.log(res);
                setTitle("");
                setDescription("");
                setRewards(0);
                setLink("");
                setType(0);
                setImg("");
                setSnackbarMessage(res.message);
                setSnackbarStatus("success");
                setIsOpenSnackbar(true);
                window.location.reload();
              }
            };

            run();
          });
      } catch (error) {
        setSnackbarMessage("Upload faild!");
        setSnackbarStatus("warning");
        setIsOpenSnackbar(true);
      }
    } else {
      if (imageSource !== undefined) {
        const formData = new FormData();
        formData.append("file", imageSource);
        try {
          const response = await axios
            .put(`${config.main_url}upload/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              setSnackbarMessage("Updated Image!");
              setSnackbarStatus("success");
              setIsOpenSnackbar(true);
            });
        } catch (error) {
          setSnackbarMessage("Upload faild!");
          setSnackbarStatus("warning");
          setIsOpenSnackbar(true);
        }
      }
      let res = await updateTask(
        { title,
          description,
          rewards,
          link,
          type: selectedGroup?.groupName,
          taskGoup: selectedGroup?.id },
        id
      );

      
      console.log(res);
      if (res.success) {
        console.log("success updated!");
        setSnackbarMessage("Updated successfully");
        setSnackbarStatus("success");
        setIsOpenSnackbar(true);
      } else {
        setSnackbarMessage(res.message);
        setSnackbarStatus("warning");
        setIsOpenSnackbar(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let id = localStorage.getItem("task_id");
    if (id === null) return;
    setDescription(localStorage.getItem("description"));
    setTaskId(localStorage.getItem("task_id"));
    setRewards(Number(localStorage.getItem("rewards")));
    setTitle(localStorage.getItem("title"));
    setLink(localStorage.getItem("link"));
    setType(Number(localStorage.getItem("type")));
    setImg(localStorage.getItem("img"));
  }, []);

  async function TaskGroups() {
    try {
      const res = await getNewTaskGroup();
      console.log(res);
      setTaskGroup(res?.mission);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    TaskGroups();
  }, []);

  return (
    <>
      <Loading disp={isLoading} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsOpenSnackbar(false);
          }}
          severity={snackbarStatus}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <Layout> */}
      <div style={{ backgroundColor: "black" }}>
        <div className="edit-task-page">
          <div className="edit-task-page-header">
            <h2
              style={{
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "700",
                color: "white",
                margin: "0px",
                lineHeight: "29.26px",
              }}
            >
              Reward /
            </h2>
            <h4
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "700",
                color: "rgba(255,255,255,0.5)",
                marginLeft: "5px",
                margin: "0px",
              }}
            >
              Add Task
            </h4>
          </div>
          <div
            style={{
              border: "2px solid #121212",
              borderRadius: "10px",
              marginTop: "24px",
            }}
          >
            <div className="edit-task-page-body">
              <div className="edit-task-page-body-left">
                <div style={{ marginBottom: "30px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="task_title"
                  >
                    Task Title
                  </label>
                  <input
                    type="text"
                    className="task_title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.currentTarget.value);
                    }}
                    name="task_title"
                    placeholder="Enter title of the reward"
                  />
                </div>
                <div style={{ marginBottom: "33px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="task_description"
                  >
                    Task Description
                  </label>

                  <input
                    type="text"
                    className="task_title"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.currentTarget.value);
                    }}
                    name="task_description"
                    rows="10"
                    cols="50"
                    placeholder="Enter description of the reward"
                  ></input>

                  <style>
                    {`
      #task_description::placeholder {
        color: rbga(255,255,255,0.7);
      }
    `}
                  </style>
                </div>
                <div style={{ marginBottom: "33px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="reward_amount"
                  >
                    Choose Orbitz Reward Amount
                  </label>
                  <input
                    className="input-number1"
                    type="number"
                    value={rewards}
                    onChange={(e) => setRewards(e.currentTarget.value)}
                    id="reward_amount"
                    name="reward_amount"
                    placeholder="Select Orbitz Reward Amount"
                  />
                </div>
                <div style={{ marginBottom: "36px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="url_link"
                  >
                    Task URL Link
                  </label>
                  <input
                    className="input-number1"
                    type="url"
                    id="url_link"
                    value={link}
                    onChange={(e) => {
                      setLink(e.currentTarget.value);
                    }}
                    name="url_link"
                    placeholder="Input Task URL Link"
                  />
                </div>
                <div style={{ marginBottom: "60px" }}>
                  <label
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#FFFFFF",
                      lineHeight: "19.5px",
                    }}
                    htmlFor="task_group"
                  >
                    Choose Task Group
                  </label>
                  <Select
                    id="task_group"
                    value={selectedGroup.groupName} // Display the groupName
                    onChange={handleChange} // Handle selection change
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      color: "white",
                      ".MuiSelect-icon": { color: "white" },
                      backgroundColor: "#333",
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select Group</em>; // Placeholder when nothing is selected
                      }
                      return selected; // Display the selected groupName
                    }}
                  >
                    {/* Dynamic menu items from taskGroup */}
                    {taskGroup?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.groupName} // Display value as groupName
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                          background: "transparent",
                          borderBottom: "1px solid rgba(18,18,18,1)",
                        }}
                      >
                        {item.groupName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <br />
                <div className="edit-page-left-button-group">
                  <Button
                    variant="contained"
                    onClick={handlePublishButtonClick}
                    disableElevation
                  >
                    {taskId.length ? "Publish" : "Publish"}
                  </Button>
                  <Button variant="outlined" onClick={handleCancelButtonClick}>
                    Cancel
                  </Button>
                </div>
              </div>
              <div className="edit-task-page-body-right">
                <label
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#FFFFFF",
                  }}
                  htmlFor="upload_image"
                >
                  Upload Task Image
                </label>
                <label className="imagesize">Image size: 52x52</label>
                <DropzoneArea
                  id="upload_image"
                  filesLimit={1}
                  acceptedFiles={["image/*"]}
                  maxFileSize={5000000}
                  showAlerts={["error", "info"]}
                  dropzoneText="Drag & Drop or "
                  value={img}
                  onChange={(files) => {
                    setImageSource(files[0]);
                  }}
                  onDropRejected={(files) => {
                    alert("File rejected: " + files[0].name);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </>
  );
};

export default EditTaskPage;
